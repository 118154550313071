<template>
  <v-navigation-drawer width="500" v-model="show" absolute right temporary>
    <v-list-item>
      <v-list-item-avatar>
        <v-icon> mdi-list-status </v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>Histórico de Pagamentos</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6" autofocus>
          {{ parcela.matricula ? parcela.matricula.aluno.nomecompleto : "" }}
        </v-list-item-title>

        <v-list-item-title>
          {{ parcela.matricula ? parcela.matricula.turma.descricao : "" }}
        </v-list-item-title>

        <v-list-item-title class="caption mt-3">
          <span class="font-weight-black">
            Número da parcela: #{{ parcela.numero ? parcela.numero : "" }}
          </span>
        </v-list-item-title>

        <v-list-item-title class="caption mt-4">
          Valor da parcela:
          <span class="blue--text">{{ parcela.valor | currency }}</span>
        </v-list-item-title>

        <v-list-item-title class="caption"
          >Valor pago:
          <span class="green--text"> {{ parcela.valorpago | currency }}</span>
        </v-list-item-title>

        <v-list-item-title class="caption"
          >Valor em aberto:
          <span class="red--text">
            {{ (parcela.valor - parcela.valorpago - parcela.desconto) | currency }}
          </span>
        </v-list-item-title>

        <v-list-item-title class="caption"
          >Valor desconto:
          <span class="red--text"> {{ parcela.desconto | currency }}</span>
        </v-list-item-title>

        <v-list-item-title class="mt-2 mb-3">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="!user.caixa"
                x-small
                color="info"
                v-bind="attrs"
                v-on="on"
                @click="() => openModalBaixarParcela(parcela, parcela.matricula)"
              >
                <v-icon small> fa fa-download </v-icon>
              </v-btn>
            </template>
            <span>Baixar parcela </span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="!user.caixa"
                x-small
                color="primary ml-2"
                v-bind="attrs"
                v-on="on"
                @click="(modalRecibo = true), reciboDaParcela(parcela.id)"
              >
                <v-icon small> mdi-receipt </v-icon>
              </v-btn>
            </template>
            <span>Recibo da parcela</span>
          </v-tooltip>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list>
      <template>
        <v-timeline align-center dense>
          <v-timeline-item
            small
            v-for="(item, index) in listaDeParcelas"
            :key="item.id"
            :color="item.estorno ? 'red' : 'blue'"
          >
            <v-row class="py-2">
              <v-col cols="6">
                <strong class=""># {{ ++index }} | </strong>
                <strong :class="item.estorno ? 'red--text' : 'blue--text'">
                  {{ item.valor | currency }}
                </strong>
                <div></div>
                <strong class="caption">{{ item.created_at | longDate }}</strong>
              </v-col>

              <v-col>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="!user.caixa"
                      x-small
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="(modalRecibo = true), comprovante(item.id)"
                    >
                      <v-icon small> mdi-receipt </v-icon>
                    </v-btn>
                  </template>
                  <span>Gerar recibo / Comprovante de pagamento</span>
                </v-tooltip>

                <v-tooltip bottom class="ml-2">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="!user.caixa"
                      x-small
                      color="primary"
                      class="ml-2"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="[adicionarItem(item), (modalEstorno = true)]"
                    >
                      <v-icon small> mdi-file-refresh </v-icon>
                    </v-btn>
                  </template>
                  <span>Estorna pagamento</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-divider></v-divider>
          </v-timeline-item>
        </v-timeline>
        <v-dialog v-model="modalEstorno" persistent max-width="400">
          <v-card>
            <v-card-title class="text-h5"> Você tem certeza? </v-card-title>
            <v-card-text v-if="!item.estorno"
              >Valor estornado: {{ item ? item.valor : "- - -" | currency }}</v-card-text
            >
            <v-card-text v-if="item.estorno">Valor já estornado.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="item.estorno"
                color="green darken-1"
                text
                @click="[estornaValor(), (modalEstorno = false)]"
              >
                Continuar
              </v-btn>
              <v-btn color="green darken-1" text @click="modalEstorno = false">
                Cancelar Estorno
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <ModalCarteira
          :title="title"
          :dialog="modalRecibo"
          :pdf="pdf"
          @CloserModalRecibo="modalRecibo = false"
        />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import ModalCarteira from "../Matriculas/EModalCarteira.vue";

export default {
  computed: {
    ...mapGetters("Auth", ["user"]),
  },

  components: {
    ModalCarteira,
  },

  data() {
    return {
      title: "Recibo",
      item: {},
      modalRecibo: false,
      dialog: false,
      pdf: null,
    };
  },

  props: {
    modalEstorno: {
      type: Boolean,
    },
    listaDeParcelas: {
      type: Array,
    },
    show: {
      type: Boolean,
    },
    parcela: {
      type: Object,
    },
    matricula: {
      type: Number,
    },
  },

  methods: {
    async comprovante(mensalidade) {
      this.pdf = await this.$services.relatoriosService.exibirRecibo(mensalidade);
    },

    async reciboDaParcela(parcelaId) {
      this.title = "Recibo da parcela";
      this.pdf = await this.$services.relatoriosService.exibirReciboDaParcela(parcelaId);
    },

    adicionarItem(item) {
      this.item = { ...item };
    },

    async estornaValor() {
      const data = {
        id: this.item.id,
        estorno: 1,
        valor: -this.item.valor,
      };
      await this.$services.parcelasService.estorno(data).then(() => {
        this.$emit("valorEstornado", true);
      });
      this.modalEstorno = false;
    },

    openModalBaixarParcela(parcela, matriculaSelecionada) {
      this.parcela = { ...parcela };
      this.$router.push({
        name: "mensalidades.baixar",
        params: { prestacao_id: this.parcela.id, matricula_id: matriculaSelecionada.id },
      });
    },
  },
};
</script>
