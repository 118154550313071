import Vue from "vue";
import VueRouter from "vue-router";
// import LoginPage from '@/Pages/LoginPage/index.vue';
import Caixas from "@/Pages/Caixas/index.vue";
import LoginPage from "@/Pages/LoginPage/index.vue";
import LogoutPage from "@/Pages/LogoutPage.vue";
import HomePage from "@/Pages/HomePage/index.vue";
import AdminPage from "@/Pages/AdminPage/index.vue";
import ListarUsuariosPage from "@/Pages/AdminPage/Usuarios/ListarUsuariosPage.vue";

import redirecionarSeForProfessor from "./guards/redirecionarSeForProfessor";
import redirecionarSeForResponsavel from "./guards/redirecionarSeForResponsavel";

import authService from "../Services/AuthService";
import mensalidades from "./mensalidades";
import franquias from "./franquias";
import turmas from "./turmas";
import series from "./series";
import disciplinas from "./disciplinas";
import alunos from "./alunos";
import professores from "./professores";
import matriculas from "./matriculas";
import usuarios from "./usuarios";
import inep from "./inep";
import gestaoDeAulas from "./gestaoDeAulas";
import aulas from "./aulas";
import acessoProfessor from "./acessoProfessor";
import acessoResponsavel from "./acessoResponsavel";
import notas from "./notas";
import relatorios from "./relatorios";
import avisos from "./avisos";
import notifiq from "./notifiq";
import movimentacao from "./movimentacao";
import sangria from "./sangria";
import CreateCaixa from "../Pages/Caixas/createCaixa.vue";
import historico from "./historico";
import store from "../store/index";
import circuitoNotas from "./circuito-notas";
import autorizacoes from "./autorizacoes";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "login" },
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
    beforeEnter(target, from, next) {
      if (authService.isLoggedIn()) {
        return next({ name: "home" });
      }
      return next();
    },
  },
  {
    path: "/home",
    name: "home",
    component: HomePage,
    beforeEnter: async (to, from, next) => {
      await redirecionarSeForResponsavel(to, from, next);
      await redirecionarSeForProfessor(to, from, next);
      return next();
    },
  },
  {
    path: "/caixas",
    name: "caixas",
    component: Caixas,
  },
  {
    path: "/caixas/create",
    name: "createCaixa",
    component: CreateCaixa,
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminPage,
  },
  {
    path: "/admin/usuarios/listar",
    name: "admin.usuarios.listar",
    component: ListarUsuariosPage,
  },
  {
    path: "/logout",
    name: "logout",
    component: LogoutPage,
  },
  ...mensalidades,
  ...franquias,
  ...turmas,
  ...series,
  ...disciplinas,
  ...alunos,
  ...professores,
  ...matriculas,
  ...usuarios,
  ...inep,
  ...gestaoDeAulas,
  ...historico,
  ...aulas,
  ...acessoProfessor,
  ...acessoResponsavel,
  ...notas,
  ...relatorios,
  ...avisos,
  ...notifiq,
  ...movimentacao,
  ...sangria,
  ...circuitoNotas,
  ...autorizacoes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name != "login" && !authService.isLoggedIn()) {
    store.commit("RouterCache/setOriginRoute", to.fullPath);
    store.commit("RouterCache/setOriginRouteNameVeirification", to.name);
    console.log("paiiiii");
    console.log(store.getters["RouterCache/getRoutes"]);
    next({
      path: "login",
      replace: true,
    });
  } else {
    next();
  }
});

export default router;
