<template>
  <v-dialog v-model="open" persistent max-width="450px">
    <v-card>
      <v-card-title>
        <span class="text-h6"> Qual o valor inicial do caixa? </span>
      </v-card-title>
      <v-card-text>
        <form>
          <v-currency-field v-model="valorInicial" outlined autofocus label="Valor Inicial" />
        </form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text small :disabled="abrindoCaixa" color="error" @click="onClose">
          <v-icon small left> fa fa-close </v-icon>
          Cancelar
        </v-btn>

        <v-btn
          color="success white--text"
          depressed
          small
          :disabled="abrindoCaixa"
          :loading="abrindoCaixa"
          @click="abrirCaixa"
        >
          <v-icon small left> fa fa-check </v-icon>
          Abrir caixa
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import caixasService from "@/Services/CaixasService";

export default {
  name: "EAbrirCaixaDialog",
  props: {
    caixa: {
      type: Object,
      default: null,
    },
    open: {
      type: Boolean,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      valorInicial: 0,
      abrindoCaixa: false,
    };
  },
  methods: {
    async abrirCaixa() {
      try {
        this.abrindoCaixa = true;
        await caixasService.abrirCaixa(this.caixa, this.valorInicial);
        this.caixa.estaAberto = true;
        this.onClose();
        this.$toast.success("Caixa aberto com sucesso");
      } catch (error) {
        this.$toast.danger(error.error.message);
      }
      this.abrindoCaixa = false;
      // this.$router.push({
      //   name: "caixas",
      // });
      // await this.loadCaixas();
    },
  },
};
</script>
