import Axios from "@/plugins/Axios";

export class SangriaService {
  async salvarSangria(sangria) {
    const response = await Axios().post(`sangrias/create`, sangria, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });

    return response.data;
  }

  async getSangrias(caixa_id) {
    const response = await Axios().get(`sangrias/${caixa_id}`);
    return response.data;
  }

  async getSangriasDataAtual(caixa_id, date) {
    const response = await Axios().get(`sangrias/${caixa_id}/${date}`);
    return response.data;
  }
}
export default new SangriaService();
