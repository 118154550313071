const state = () => ({
  originRoute: null,
  originRouteNameVeirification: null,
});

const mutations = {
  setOriginRoute(state, p) {
    state.originRoute = p;
  },

  setOriginRouteNameVeirification(state, p) {
    state.originRouteNameVeirification = p;
  },

  clearRoutes(state) {
    state.originRouteNameVeirification = null;
    state.originRoute = null;
  },
};

const actions = {};
const getters = {
  getRoutes(state) {
    const data = {
      originRoute: state.originRoute,
      originRouteNameVeirification: state.originRouteNameVeirification,
    };
    return data;
  },
};

const namespaced = true;

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
