/* eslint-disable no-param-reassign */
import axios from "axios";
import { $toast } from "vuetify-snackbar-toast";
import router from "@/router";
import authService from "@/Services/AuthService";
import store from "../store/index";

function getCsrfToken() {
  const cookieList = document.cookie.split(";").filter((str) => str.includes("csrftoken"));
  if (cookieList.length > 0) {
    const cookie = cookieList[0].replace("csrftoken=", "").trim();
    return cookie;
  }
  return null;
}

export default () => {
  const options = { baseURL: process.env.VUE_APP_API_HOST };
  const axiosInstance = axios.create(options);
  axiosInstance.interceptors.request.use((config) => {
    const token = authService.getAccessToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;
    const csrfToken = getCsrfToken();
    if (csrfToken) config.headers["X-CSRF-TOKEN"] = csrfToken;
    return config;
  });
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401 && authService.getAccessToken()) {
        $toast.warn("Sua sessão expirou");
        store.commit("RouterCache/setOriginRoute", router.currentRoute.fullPath);
        store.commit("RouterCache/setOriginRouteNameVeirification", router.currentRoute.name);
        const currentRouteName = router.currentRoute.name;
        if (currentRouteName !== "login" && currentRouteName !== "logout") {
          router.push({ name: "logout" });
        }
        return Promise.reject(new Error("Sua sessão expirou"));
      }
      if (error.response && error.response.status === 403) {
        return Promise.reject(new Error("Você não tem permissão para acessar esse recurso"));
      }
      if (error.response) {
        if (error.response.data) {
          return Promise.reject(error.response.data);
        }
      }
      return Promise.reject(error);
    }
  );
  return axiosInstance;
};
