<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'alunos' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Aluno
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-tabs
                v-model="tab"
                background-color="deep-purple accent-4"
                centered
                dark
                icons-and-text
              >
                <v-tabs-slider></v-tabs-slider>

                <v-tab href="#tab-1">
                  Aluno
                  <v-icon>fa-graduation-cap</v-icon>
                </v-tab>

                <v-tab href="#tab-2">
                  Endereço do Aluno
                  <v-icon>fa-address-card</v-icon>
                </v-tab>

                <v-tab href="#tab-3">
                  Responsável Financeiro
                  <v-icon>mdi-account-box</v-icon>
                </v-tab>
                <v-tab href="#tab-4">
                  Endereço do Responsável
                  <v-icon>fa-address-card</v-icon>
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item :value="'tab-1'">
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <template>
                            <!--UPLOAD-->
                            <form
                              enctype="multipart/form-data"
                              novalidate
                              v-if="isInitial || isSaving"
                            >
                              <h4>Carregar imagem</h4>
                              <div class="dropbox">
                                <input
                                  type="file"
                                  multiple
                                  :name="uploadFieldName"
                                  @change="
                                    filesChange($event.target.files);
                                    fileCount = $event.target.files.length;
                                  "
                                  accept="image/*"
                                  class="input-file"
                                />
                                <p v-if="isInitial">
                                  Arraste uma imagem aqui para começar<br />
                                  ou clique para navegar
                                </p>
                                <v-col class="text-center" v-if="isSaving">
                                  <img class="text-center" width="150x" :src="base64Img" />
                                  <p>Imagem carregada!</p>
                                </v-col>
                              </div>
                            </form>
                          </template>
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="12">
                          <ValidationProvider
                            name="Nome Completo"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <e-label>Nome Completo</e-label>
                            <v-text-field
                              :error-messages="errors"
                              v-model="form.nomecompleto"
                              dense
                              solo
                            />
                          </ValidationProvider>
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Identificação única(INEP)</e-label>
                          <v-text-field
                            v-model="form.codigo_inep"
                            :loading="pesquisandoINEP"
                            dense
                            solo
                          >
                          </v-text-field>
                        </v-col>

                        <!-- <v-col class="pt-0 pb-0" cols="6">
                          <e-label>Nome social</e-label>
                          <v-text-field
                            placeholder="(Opicional)"
                            v-model="form.nome_social"
                            dense
                            solo
                          />
                        </v-col> -->
                        <v-col class="pt-0 pb-0" cols="3">
                          <ValidationProvider
                            name="Data de Nascimento"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <e-label>Data de Nascimento</e-label>
                            <e-date-picker
                              v-model="form.data_nascimento"
                              placeholder="dd/mm/yyyy"
                              :error-messages="errors"
                              @change="teste"
                            ></e-date-picker>
                          </ValidationProvider>
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <!-- <ValidationProvider name="CPF" rules="required" v-slot="{ errors }"> -->
                          <e-label>CPF</e-label>
                          <v-text-field
                            v-model="form.cpf"
                            v-mask="'###.###.###-##'"
                            :error-messages="errors"
                            dense
                            solo
                          />
                          <!-- </ValidationProvider> -->
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <!-- <ValidationProvider name="Sexo" rules="required" v-slot="{ errors }"> -->
                          <e-label>Gênero</e-label>
                          <e-autocomplete
                            :items="$constants.sexoList"
                            :return-object="false"
                            :item-value="(value) => value.id"
                            :item-text="(value) => value.descricao"
                            v-model="form.sexo_id"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                          <!-- </ValidationProvider> -->
                        </v-col>
                        <!-- <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Cor/Raça</e-label>
                          <e-autocomplete
                            :items="$constants.corOuRacaList"
                            :return-object="false"
                            v-model="form.cor_ou_raca"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col> -->
                        <!-- <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Estado Civil</e-label>
                          <e-autocomplete
                            :items="$constants.estadoCivilList"
                            :return-object="false"
                            :item-text="(value) => value.descricao"
                            :item-value="(value) => value.id"
                            v-model="form.estadocivil_id"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col> -->
                        <!-- <v-col class="pt-0 pb-0" cols="3">
                          <e-label>País de Nascimento</e-label>
                          <v-text-field v-model="form.naturalidade" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Nacionalidade</e-label>
                          <e-autocomplete
                            :items="$constants.nacionalidadeList"
                            :return-object="false"
                            :item-text="(value) => value"
                            :item-value="(value) => value"
                            v-model="form.nacionalidade"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>UF de nascimento</e-label>
                          <e-autocomplete
                            :items="$constants.estadosList"
                            :return-object="false"
                            :item-text="(item) => item.codigo"
                            :item-value="(item) => item.codigo"
                            v-model="form.estadualidade"
                            @change="(event) => atualizarEstado(event)"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col> -->
                        <!-- <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Município de nascimento</e-label>
                          <e-autocomplete
                            :items="municipiosNasc"
                            :return-object="false"
                            :item-text="(item) => item"
                            :item-value="(item) => item"
                            v-model="form.municipalidade"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col> -->
                        <!-- <v-col class="pt-0 pb-0" cols="3">
                          <e-label>RG</e-label>
                          <v-text-field v-model="form.registrogeral" dense solo />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Orgão Expeditor</e-label>
                          <e-autocomplete
                            :items="$constants.orgaosEmissores"
                            :return-object="false"
                            :item-text="(item) => item.sigla + ' - ' + item.descricao"
                            :item-value="(item) => item.id"
                            v-model="form.orgaoemissor_id"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Estado Emissor</e-label>
                          <e-autocomplete
                            :items="$constants.estadosList"
                            :return-object="false"
                            :item-text="(item) => item.descricao"
                            :item-value="(item) => item.id"
                            v-model="form.rgestado_id"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Data de Expedição</e-label>
                          <e-date-picker
                            v-model="form.rg_dataemissao"
                            placeholder="dd/mm/aaaa"
                          ></e-date-picker>
                        </v-col> -->
                        <v-col class="pt-0 pb-0" cols="12">
                          <e-label>Nome do Pai</e-label>
                          <v-text-field v-model="form.filiacao1" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="4">
                          <!-- <ValidationProvider name="CPF" rules="required" v-slot="{ errors }"> -->
                          <e-label>CPF</e-label>
                          <v-text-field
                            v-model="form.cpf_filiacao1"
                            v-mask="'###.###.###-##'"
                            :error-messages="errors"
                            dense
                            solo
                          />
                          <!-- </ValidationProvider> -->
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="4">
                          <e-label>RG</e-label>
                          <v-text-field v-model="form.rg_filiacao1" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="4">
                          <e-label>Orgão Expeditor</e-label>
                          <e-autocomplete
                            :items="$constants.orgaosEmissores"
                            :return-object="false"
                            :item-text="(item) => item.sigla + ' - ' + item.descricao"
                            :item-value="(item) => item.id"
                            v-model="form.orgao_expeditor_filiacao1"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="12">
                          <ValidationProvider
                            name="Nome da Mãe"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <e-label>Nome da Mãe</e-label>
                            <v-text-field
                              :error-messages="errors"
                              v-model="form.filiacao2"
                              dense
                              solo
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="4">
                          <ValidationProvider name="CPF" rules="required" v-slot="{ errors }">
                            <e-label>CPF</e-label>
                            <v-text-field
                              v-model="form.cpf_filiacao2"
                              v-mask="'###.###.###-##'"
                              :error-messages="errors"
                              dense
                              solo
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="4">
                          <e-label>RG</e-label>
                          <v-text-field v-model="form.rg_filiacao2" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="4">
                          <e-label>Orgão Expeditor</e-label>
                          <e-autocomplete
                            :items="$constants.orgaosEmissores"
                            :return-object="false"
                            :item-text="(item) => item.sigla + ' - ' + item.descricao"
                            :item-value="(item) => item.id"
                            v-model="form.orgao_expeditor_filiacao2"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-5 pb-0" cols="12">
                          <e-label>Informações Complementares (Documentos Entregues)</e-label>
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <v-checkbox
                            v-model="form.doc_transferencia"
                            label="Transferência"
                            value="1"
                          ></v-checkbox>
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <v-checkbox
                            v-model="form.doc_declaracao"
                            label="Declaração"
                            value="1"
                          ></v-checkbox>
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="4">
                          <v-checkbox
                            v-model="form.doc_comp_residencia"
                            label="Comprovante de Residência"
                            value="1"
                          ></v-checkbox>
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="4">
                          <v-checkbox
                            v-model="form.doc_nasc"
                            label="Registro de Nascimento"
                            value="1"
                          ></v-checkbox>
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="4">
                          <v-checkbox
                            v-model="form.doc_cpf_ou_rg"
                            label="CPF e do RG (Responsável)"
                            value="1"
                          ></v-checkbox>
                        </v-col>

                        <!-- <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Nº Cert. Nascimento</e-label>
                          <v-text-field v-model="form.certificado_nascimento" dense solo />
                        </v-col> -->

                        <!-- <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Título de Eleitor</e-label>
                          <v-text-field
                            v-model="form.tituloeleitor"
                            v-mask="'#### #### ####'"
                            dense
                            solo
                          />
                        </v-col> -->
                        <!-- <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Zona Eleitoral</e-label>
                          <v-text-field v-model="form.zona" v-mask="'####'" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Seção Eleitoral</e-label>
                          <v-text-field v-model="form.secao" v-mask="'####'" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Nº CSM</e-label>
                          <v-text-field v-model="form.folha" dense solo />
                        </v-col> -->

                        <!-- <v-col class="pt-0 pb-0" cols="3">
                          <ValidationProvider name="Email" rules="required" v-slot="{ errors }">
                            <e-label>Email</e-label>
                            <v-text-field
                              :error-messages="errors"
                              placeholder="email@email.com"
                              v-model="form.email"
                              dense
                              solo
                            />
                          </ValidationProvider>
                        </v-col> -->
                        <!-- <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Profissão</e-label>
                          <v-text-field v-model="form.profissao" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Local de Trabalho</e-label>
                          <v-text-field v-model="form.local_trabalho" dense solo />
                        </v-col> -->

                        <!-- <v-col class="pt-0 pb-0" cols="4">
                          <e-label>Obervações Emergência</e-label>
                          <v-text-field v-model="form.observacoes_emergencia" dense solo />
                        </v-col> -->
                        <!-- <v-col class="pt-0 pb-0" cols="5">
                          <e-label>Obervações Pessoas</e-label>
                          <v-text-field v-model="form.observacoes_pessoais" dense solo />
                        </v-col> -->
                        <!-- aqui -->
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="'tab-2'">
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <h4>DADOS SOBRE A ENDEREÇO DO(A) ALUNO(A)</h4>
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <ValidationProvider name="CEP" rules="required" v-slot="{ errors }">
                            <e-label>CEP</e-label>
                            <v-text-field
                              :error-messages="errors"
                              v-mask="`##.###-###`"
                              v-model="form.cep"
                              dense
                              solo
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="5">
                          <e-label>Endereço</e-label>
                          <v-text-field v-model="form.endereco" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="1">
                          <e-label>Nº</e-label>
                          <v-text-field v-mask="'#######'" v-model="form.numero" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Bairro</e-label>
                          <v-text-field v-model="form.bairro" dense solo />
                        </v-col>

                        <!-- <v-col class="pt-0 pb-0" cols="5">
                          <e-label>Complemento</e-label>
                          <v-text-field v-model="form.complemento" dense solo />
                        </v-col> -->
                        <!-- <v-col class="pt-0 pb-0" cols="2">
                          <e-label> País de residência </e-label>
                          <v-text-field v-model="form.pais_residencia" dense solo />
                        </v-col> -->
                        <v-col class="pt-0 pb-0" cols="2">
                          <e-label> Estado </e-label>
                          <e-autocomplete
                            :items="$constants.estadosList"
                            :return-object="false"
                            :item-text="(item) => item.codigo"
                            :item-value="(item) => item.codigo"
                            v-model="form.uf_residencia"
                            @change="(event) => atualizarEstadoRes(event)"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label> Cidade </e-label>
                          <e-autocomplete
                            :items="municipiosRes"
                            :return-object="false"
                            :item-text="(item) => item"
                            :item-value="(item) => item"
                            v-model="form.municipio_residencia"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Celular</e-label>
                          <v-text-field
                            v-mask="'(##) # ####-####'"
                            v-model="form.celular"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Telefone</e-label>
                          <v-text-field
                            v-mask="'(##) ####-####'"
                            v-model="form.telefone1"
                            dense
                            solo
                          />
                        </v-col>

                        <!-- <v-col class="pt-0 pb-0" cols="2">
                          <e-label>Celular</e-label>
                          <v-text-field
                            v-mask="'(##) # ####-####'"
                            v-model="form.telefone2"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="2">
                          <e-label>Telefone</e-label>
                          <v-text-field
                            v-mask="'(##) ####-####'"
                            v-model="form.telefone3"
                            dense
                            solo
                          />
                        </v-col> -->
                        <!-- <v-col class="pt-0 pb-0" cols="2">
                          <e-label>Ramal</e-label>
                          <v-text-field v-mask="'####'" v-model="form.ramal" dense solo />
                        </v-col> -->
                        <!-- <v-col class="pt-0 pb-0" cols="2">
                          <e-label> Zona de Residência </e-label>
                          <e-autocomplete
                            :items="$constants.zonaResidenciaList"
                            :return-object="false"
                            v-model="form.zona_residencia"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col> -->
                        <!-- <v-col class="pt-0 pb-0" cols="4">
                          <e-label>Localização diferenciada de residência</e-label>
                          <e-autocomplete
                            :items="$constants.localizacaoDiferenciadaDeResidenciaList"
                            :return-object="false"
                            v-model="form.zona_diferenciada_residencia"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col> -->
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="'tab-3'">
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <!-- <v-col class="pt-0 pb-0" cols="3">
                          <e-label>&nbsp;</e-label>
                          <v-btn @click="repetirDadosAluno()" color="light-green" block>
                            Repetir dados do Aluno
                          </v-btn>
                        </v-col> -->
                        <v-col class="pt-0 pb-0" cols="12">
                          <ValidationProvider
                            name="nome completo do responsavel"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <e-label>Nome Completo do Responsavel</e-label>
                            <v-text-field
                              :error-messages="errors"
                              v-model="responsavel.descricao"
                              dense
                              solo
                            />
                          </ValidationProvider>
                        </v-col>

                        <!-- <v-col class="pt-0 pb-0" cols="8">
                          <e-label>Nome social</e-label>
                          <v-text-field
                            placeholder="(Opicional)"
                            v-model="responsavel.nome_social"
                            dense
                            solo
                          />
                        </v-col> -->
                        <!-- <v-col class="pt-0 pb-0" cols="2">
                          <e-label>Tipo Pessoa</e-label>
                          <e-autocomplete
                            :items="$constants.tipoPessoa"
                            :return-object="false"
                            :item-text="(item) => item"
                            :item-value="(item) => item"
                            v-model="responsavel.tipo_pessoa"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col> -->

                        <v-col class="pt-0 pb-0" cols="2">
                          <ValidationProvider name="CPF" rules="required" v-slot="{ errors }">
                            <e-label>CPF</e-label>
                            <v-text-field
                              :error-messages="errors"
                              v-model="responsavel.cpf"
                              v-mask="'###.###.###-##'"
                              dense
                              solo
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="2">
                          <e-label>RG</e-label>
                          <v-text-field v-model="responsavel.rg" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Orgão Expeditor</e-label>
                          <e-autocomplete
                            :items="$constants.orgaosEmissores"
                            :return-object="false"
                            :item-text="(item) => item.sigla + ' - ' + item.descricao"
                            :item-value="(item) => item.id"
                            v-model="responsavel.orgaoemissor_id"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <!--
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Estado Emissor</e-label>
                          <e-autocomplete
                            :items="$constants.estadosList"
                            :return-object="false"
                            :item-text="(item) => item.descricao"
                            :item-value="(item) => item.id"
                            v-model="responsavel.estadoemissor_id"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Data de Expedição</e-label>
                          <e-date-picker
                            v-model="responsavel.data_expedicao"
                            placeholder="dd/mm/aaaa"
                          ></e-date-picker>
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <ValidationProvider
                            name="Nome Completo do Responsavel"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <e-label>Data de nascimento</e-label>
                            <e-date-picker
                              :error-messages="errors"
                              v-model="responsavel.data_nascimento"
                              placeholder="dd/mm/aaaa"
                            ></e-date-picker>
                          </ValidationProvider>
                        </v-col> -->
                        <!-- <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Estado Civil</e-label>
                          <e-autocomplete
                            :items="$constants.estadoCivilList"
                            :return-object="false"
                            :item-text="(value) => value.descricao"
                            :item-value="(value) => value.id"
                            v-model="responsavel.estadocivil_id"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col> -->
                        <!-- <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Nacionalidade</e-label>
                          <e-autocomplete
                            :items="$constants.nacionalidadeList"
                            :return-object="false"
                            :item-text="(value) => value"
                            :item-value="(value) => value"
                            v-model="responsavel.naturalidade"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>UF de nascimento</e-label>
                          <e-autocomplete
                            :items="$constants.estadosList"
                            :return-object="false"
                            :item-text="(item) => item.codigo"
                            :item-value="(item) => item.codigo"
                            v-model="responsavel.estadualidade"
                            @change="(event) => atualizarEstado(event)"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Município de nascimento</e-label>
                          <e-autocomplete
                            :items="municipiosNasc"
                            :return-object="false"
                            :item-text="(item) => item"
                            :item-value="(item) => item"
                            v-model="responsavel.municipalidade"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col> -->
                        <v-col class="pt-0 pb-0" cols="4">
                          <e-label>E-mail</e-label>
                          <v-text-field
                            :error-messages="errors"
                            placeholder="email@email.com"
                            v-model="responsavel.email"
                            dense
                            solo
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="'tab-4'">
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <h4>ENDEREÇO DO(A) RESPONSÁVEL DO(A) ALUNO(A)</h4>
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="2">
                          <!-- <ValidationProvider name="CEP" rules="required" v-slot="{ errors }"> -->
                          <e-label>CEP</e-label>
                          <v-text-field
                            :error-messages="errors"
                            v-mask="`##.###-###`"
                            v-model="responsavel.cep"
                            dense
                            solo
                          />
                          <!-- </ValidationProvider> -->
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="5">
                          <e-label>Endereço</e-label>
                          <v-text-field v-model="responsavel.logradouro" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="2">
                          <e-label>Nº</e-label>
                          <v-text-field
                            v-mask="'#######'"
                            v-model="responsavel.numero"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Bairro</e-label>
                          <v-text-field v-model="responsavel.bairro" dense solo />
                        </v-col>

                        <!-- <v-col class="pt-0 pb-0" cols="5">
                          <e-label>Complemento</e-label>
                          <v-text-field v-model="responsavel.complemento" dense solo />
                        </v-col> -->
                        <!-- <v-col class="pt-0 pb-0" cols="2">
                          <e-label> País de residência </e-label>
                          <v-text-field v-model="responsavel.pais" dense solo />
                        </v-col> -->
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label> Estado </e-label>
                          <e-autocomplete
                            :items="$constants.estadosList"
                            :return-object="false"
                            :item-text="(item) => item.codigo"
                            :item-value="(item) => item.codigo"
                            v-model="responsavel.estado"
                            @change="(event) => atualizarEstadoRes(event)"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label> Cidade </e-label>
                          <e-autocomplete
                            :items="municipiosRes"
                            :return-object="false"
                            :item-text="(item) => item"
                            :item-value="(item) => item"
                            v-model="responsavel.cidade"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Celular</e-label>
                          <v-text-field
                            v-mask="'(##) # ####-####'"
                            v-model="responsavel.celular1"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Telefone</e-label>
                          <v-text-field
                            v-mask="'(##) ####-####'"
                            v-model="responsavel.celular2"
                            dense
                            solo
                          />
                        </v-col>
                        <!-- <v-col class="pt-0 pb-0" cols="4">
                          <e-label>Ramal</e-label>
                          <v-text-field v-mask="'####'" v-model="responsavel.ramal" dense solo />
                        </v-col> -->
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>

        <v-col cols="12">
          <v-btn
            color="primary"
            :disabled="submittingForm"
            :loading="submittingForm"
            type="submit"
            block
            >Enviar Dados</v-btn
          >
        </v-col>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import { mapGetters } from "vuex";
import { $toast } from "vuetify-snackbar-toast";
import { ref } from "vue";
import ELabel from "../../components/ELabel.vue";

const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;
export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        // if (formValue) {
        //   this.recursos_saeb = formValue.recursos_saeb;
        // } else {
        //   this.recursos_saeb = [];
        // }
        if (this.nextInepTimeoutCall) {
          clearTimeout(this.nextInepTimeoutCall);
        }

        this.nextInepTimeoutCall = setTimeout(() => {
          this.verificarCodigoINEP();
        }, 500);
      },
    },
    responsavel: {
      deep: true,
      handler() {},
    },
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    selected() {
      return this.form.selected;
    },
    // ...mapGetters("Alunos", ["alunosList", "carregandoAlunos"]),
    // ...mapGetters("Alunos", ["alunos"]),
    ...mapGetters("Auth", ["user"]),
  },
  mounted() {
    this.reset();
    this.loadFormData();
  },

  data() {
    return {
      submittingForm: false,
      recursos_saeb: [],
      form: {
        naturalidade: "Brasileira",
      },
      responsavel: {
        descricao: " ",
      },
      nextInepTimeoutCall: null,
      pesquisandoINEP: false,
      municipiosNasc: [],
      municipiosRes: [],
      edit: true,
      imageAluno: null,
      countImagem: false,
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "photos",
      tab: "",
    };
  },

  methods: {
    // ...mapActions("Alunos", ["loadAlunos"]),
    atualizarEstado(value) {
      if (value) {
        this.municipiosNasc = this.$constants.municipios[value].cidades;
      }
    },
    atualizarEstadoRes(value) {
      if (value) {
        this.municipiosRes = this.$constants.municipios[value].cidades;
      }
    },
    teste() {
      console.log(this.form.data_nascimento);
    },
    // repetirDadosAluno(){
    //   this.form.nome_completo_responsavel = this.form.nomecompleto;
    //   console.log("Nome completo: " + this.form.nomecompleto);
    //   console.log("Nome completo: " + this.form.nome_completo_responsavel);
    // },

    async getResponsavel(responsavel_id) {
      try {
        const aluno = await this.$services.alunosService.getResponsavel(responsavel_id);
        return aluno;
      } catch (error) {
        return this.$handleError(error);
      }
    },

    async pegarAluno() {
      try {
        const { aluno_id } = this.$route.params;
        const aluno = await this.$services.alunosService.pegarAluno(aluno_id);
        return aluno;
      } catch (error) {
        return this.$handleError(error);
      }
    },

    async submitForm() {
      this.form.especial = Boolean(this.form.especial);
      this.form.recursos_saeb = this.recursos_saeb;
      this.submittingForm = true;

      try {
        const response = await this.$services.alunosService.criaOuAtualizaResposavel(
          this.responsavel
        );
        this.form.responsavel_id = response;
      } catch (error) {
        this.submittingForm = false;
        this.$toast.danger(error.message);
        return;
      }

      try {
        if (this.editing) {
          const response = await this.$services.alunosService.atualizarAluno(this.form);
          await this.uploadImage(response.id);
          this.$toast.success("Aluno(a) Atualizado(a) com sucesso");
        } else {
          const response = await this.$services.alunosService.criarAluno(this.form);
          await this.uploadImage(response.id);
          this.$toast.success("Aluno criado com sucesso");
        }
        this.submittingForm = false;
        this.$router.push({ name: "alunos" });
      } catch (error) {
        this.submittingForm = false;
        this.$toast.danger(error.error.message);
      }
      this.submittingForm = false;
    },

    async loadFormData() {
      const aluno = await this.pegarAluno();
      if (aluno) {
        this.edit = false;
      }
      const responsavel = await this.getResponsavel(aluno.responsavel_id);
      this.atualizarEstadoRes(aluno.uf_residencia);
      this.atualizarEstado(aluno.estadualidade);
      if (aluno.imagem) {
        this.currentStatus = STATUS_SAVING;
      }
      this.recursos_saeb = aluno.recursos_saeb ?? [];
      this.form = {
        ...aluno,
      };
      this.responsavel = {
        ...responsavel[0],
      };
      this.form.orgao_expeditor_filiacao1 = parseInt(this.form.orgao_expeditor_filiacao1, 10);
      this.form.orgao_expeditor_filiacao2 = parseInt(this.form.orgao_expeditor_filiacao2, 10);
      this.responsavel.orgaoemissor_id = parseInt(this.responsavel.orgaoemissor_id, 10);
    },

    async uploadImage(id) {
      try {
        await this.$services.imageService.uploadImage("alunos/upload-image", this.imageAluno, id);
      } catch (error) {
        this.$handleError(error);
      }
    },

    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },

    filesChange(fileList) {
      // handle file changes
      if (!fileList.length) return;

      // append the files to FormData
      if (fileList.length > 1) {
        this.countImagem = true;
        setTimeout(() => {
          this.countImagem = false;
        }, 4000);
      }
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.imageAluno = fileList[x];
        return fileList[x];
      });

      this.currentStatus = STATUS_SAVING;
    },

    async verificarCodigoINEP() {
      if (this.editing || !this.form.codigo_inep) return;
      this.pesquisandoINEP = true;
      try {
        const aluno = await this.$services.alunosService.getAlunoComINEP(this.form.codigo_inep);
        if (aluno) {
          if (aluno.configuracao_id !== this.user.configuracao_id) {
            this.$modals.warn({
              title: "Você gostaria de transferir esse(a) aluno(a)?",
              message: `Encontramos o(a) aluno(a) <strong>${aluno.nomecompleto}</strong> com INEP <strong>${aluno.codigo_inep}</strong> registrado em outra escola, você gostaria de transferí-lo para sua escola?`,
              buttons: [
                {
                  text: "Cancelar",
                  callback: () => {
                    this.$modals.close();
                  },
                  props: {
                    color: "warning",
                    small: true,
                  },
                },
                {
                  text: "Confirmar",
                  callback: async () => {
                    this.$modals.setOptions({ loading: true });
                    await this.$services.alunosService.atualizarAluno({
                      ...aluno,
                      configuracao_id: this.user.configuracao_id,
                    });
                    this.$modals.close();
                    this.$toast.success("Aluno transferido com sucesso!");
                    this.$router.push({ name: "alunos" });
                  },
                  props: {
                    color: "success",
                    // text: true,
                    small: true,
                  },
                },
              ],
            });
          } else {
            this.$modals.info({
              title: "Este(a) aluno(a) já está cadastrado na sua escola!",
              message: "Verificamos que este(a) aluno(a) já está cadastrado na sua escola, ",
              buttons: [
                {
                  text: "Confirmar",
                  callback: () => {
                    this.$modals.close();
                    this.$toast.info("Este(a) aluno(a) já está cadastrado na sua escola");
                    this.$router.push({ name: "alunos" });
                  },
                  props: {
                    color: "success",
                    small: true,
                  },
                },
              ],
            });
          }
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.pesquisandoINEP = false;
    },
  },
};
</script>

<!-- SASS styling -->
<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

.bottomButtons {
  overflow: auto;
}

.btn {
  float: right;
}

.options {
  margin-top: 20px;
  margin-left: 15px;
  font-size: large;
}

.texto {
  margin-left: 8px;
  margin-right: 10px;
  font-size: large;
  padding-bottom: 10px;
}

.step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: flex;
  opacity: 0.5;
}

.step.active {
  opacity: 1;
}

.step.finish {
  background-color: #04aa6d;
}

.invalid {
  background-color: #ffdddd;
}

.tab {
  display: none;
}

.block {
  display: block;
}

.none {
  visibility: hidden;
}
</style>
