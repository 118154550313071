<template>
  <v-col cols="12" md="4" sm="12">
    <v-card :loading="loading" class="mx-auto" height="180">
      <template slot="progress">
        <v-progress-linear color="indigo" indeterminate></v-progress-linear>
      </template>

      <v-card-text>
        <div class="text-h6 font-weight-black">Gênero</div>
      </v-card-text>

      <apexchart height="100" :options="chartOptions" :series="series"></apexchart>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "GeneroCard",
  mounted() {
    this.getGenero();
  },
  data() {
    return {
      qtd: 0,
      loading: true,
      series: [44, 55],
      chartOptions: {
        chart: {
          height: 400,
          type: "donut",
          // offsetY: -20,
          offsetX: -10,
        },
        legend: {
          // show: true,
          // position: "right",
          offsetY: 5,
          // offsetX: -20,
        },
        labels: ["Masculino", "Feminino"],
        responsive: [
          {
            breakpoint: 400,
            options: {
              chart: {
                width: 400,
              },
              legend: {
                position: "bottom",
                offsetY: -80,
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    async getGenero() {
      const response = await this.$services.alunosService.generos();
      const payload = Object.values(response.data);
      this.loading = false;
      this.series = [];
      this.series.push(parseInt(payload[0], 10));
      this.series.push(parseInt(payload[1], 10));
    },
  },
};
</script>

<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
