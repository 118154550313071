<template>
  <v-dialog v-model="showNovaParcela" persistent max-width="1100px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Nova(s) Parcela(s)</span>
        <v-spacer></v-spacer>

        <v-btn class="ma-2 white--text" small color="red" @click="() => limparCampos()">
          <v-icon> mdi-close-circle</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="mt-5">
          <v-col cols="12" sm="3" md="3">
            <v-currency-field filled v-model="valorMensal" autofocus label="Valor Parcela" />
          </v-col>

          <v-col cols="12" sm="3" md="3">
            <v-currency-field
              :background-color="error ? 'deep-orange lighten-4' : ''"
              :class="error ? 'animate__animated animate__flash animate__delay-2s' : ''"
              filled
              v-model="desconto"
              label="Desconto"
            />
          </v-col>

          <v-col cols="12" sm="3" md="3">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="data"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  @select="
                    data = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                      .toISOString()
                      .substr(0, 10)
                  "
                  v-model="dataFormatada"
                  label="Vencimento"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  filled
                ></v-text-field>
              </template>
              <v-date-picker v-model="data" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(data)"> OK </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="3" md="3">
            <v-select
              v-model="qtdParcelas"
              filled
              :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
              label="Qtd Parcelas*"
              required
            ></v-select>
          </v-col>
        </v-row>

        <v-row v-if="error">
          <v-col>
            <v-alert type="error" border="bottom" class="animate__animated animate__headShake">
              <template v-slot:prepend="">
                <v-icon x-large> fa fa-warning </v-icon>
              </template>
              <div class="ml-3">Não é permitido o desconto ser maior que o valor da parcela.</div>
            </v-alert>
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-row class="mt-4 mb-4">
          <v-col cols="8">
            <p class="caption">
              * Verifique as informações geradas abaixo, pois as mesmas contém dados previamente
              preenchidos tais como: Valor da parcela, Desconto, Vencimento e Quantidade de
              parcelas.
            </p>
            <p class="caption">
              ** Ao concluir que as informações estão corretas, clique no botão abaixo: "VINCULAR
              PARCELAS"
            </p>
          </v-col>

          <v-col class="text-right">
            <v-btn
              :disabled="!valorMensal || !data || error"
              color="secondary"
              @click="() => criarParcelas()"
            >
              Visualizar Parcelas
            </v-btn>
          </v-col>
        </v-row>

        <!-- <v-divider vertical></v-divider> -->
        <!-- <v-spacer></v-spacer> -->
        <v-divider></v-divider>
      </v-card-text>
      <v-col>
        <v-row class="mx-0">
          <v-col class="text-left">
            <v-btn
              v-if="parcelas.length > 0"
              :disabled="!parcelas.length > 0"
              class="white--text"
              color="red"
              @click="() => limparParcelas()"
            >
              <v-icon left> mdi-broom </v-icon>
              Limpar listagem
            </v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              v-if="parcelas.length > 0"
              :disabled="!parcelas.length > 0"
              class="white--text"
              color="green"
              @click="() => vincularParcelas()"
            >
              Vincular parcelas
            </v-btn>
          </v-col>
        </v-row>

        <v-col></v-col>

        <v-timeline dense clipped v-if="parcelas.length > 0">
          <v-timeline-item
            v-for="(item, index) in parcelas"
            :key="index"
            class="mb-4"
            color="blue"
            icon-color="grey lighten-2"
            small
          >
            <v-row justify="space-between">
              <v-card-text class="py-0">
                <v-list-item-content>
                  <v-list-item-title class="mt-3 caption">
                    <span class="font-weight-bold"> #{{ ++index }}</span> - Valor Mensal:
                    <span class="font-weight-bold"> {{ item.valorMensal | currency }}</span>

                    <span class="font-weight-bold"></span> | Desconto:
                    <span class="font-weight-bold"> {{ item.desconto | currency }}</span>

                    <span class="font-weight-bold"></span> | Valor a ser pago:
                    <span class="font-weight-bold">
                      {{ (item.valorMensal - item.desconto) | currency }}
                    </span>
                  </v-list-item-title>

                  <v-list-item-subtitle class="mt-3 caption">
                    Vencimento: {{ item.vencimento | longDate }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-card-text>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      error: false,
      desconto: 0,
      parcelas: [],
      dataFormatada: null,
      data: null,
      menu: false,
      valorMensal: 0,
      qtdParcelas: 1,
    };
  },
  props: {
    showNovaParcela: {
      type: Boolean,
    },
    matricula: {
      type: Number,
    },
  },
  watch: {
    data(val) {
      this.dataFormatada = this.formatDate(this.data);
    },

    desconto(val) {
      this.error = val > this.valorMensal;
    },
  },
  methods: {
    formatDate(data) {
      if (!data) return null;
      const [year, month, day] = data.split("-");
      return `${day}/${month}/${year}`;
    },

    criarParcelas() {
      const numeroDeParcelas = this.qtdParcelas;
      this.parcelas = [];
      for (let index = 0; index < numeroDeParcelas; index++) {
        const d = new Date(this.data);
        d.setMonth(d.getMonth() + index);
        const data_ = d.toISOString().substr(0, 10);

        this.parcelas.push({
          matricula: this.matricula,
          valorMensal: this.valorMensal,
          data: data_.toString(),
          desconto: this.desconto,
          vencimento: this.adicionarMes(this.data, index),
        });
      }
    },

    adicionarMes(data, index) {
      const newDate = new Date(data);
      newDate.setMonth(newDate.getMonth() + index);
      newDate.setDate(newDate.getDate() + 1);
      return newDate;
    },

    limparCampos() {
      this.showNovaParcela = false;
      this.desconto = 0;
      this.valorMensal = 0;
      this.data = null;
      this.qtdParcelas = 1;
      this.parcelas = [];
    },

    limparParcelas() {
      this.parcelas = [];
    },

    async vincularParcelas() {
      this.$loaderService.open("Vinculando matrículas");
      try {
        const formulario = [...this.parcelas];
        await this.$services.parcelasService.novaParcela({
          formulario,
        });
        this.$loaderService.close();
        this.limparCampos();
        this.showNovaParcela = false;
        this.$emit("matriculasVinculadas", true);
      } catch (error) {
        this.$handleError(error);
        this.$loaderService.close();
      }
      this.$loaderService.close();
    },
  },
};
</script>
