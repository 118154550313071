<template>
  <v-navigation-drawer
    :value="open"
    absolute
    right
    temporary
    @input="(newValue) => $emit('input', newValue)"
  >
    <v-list-item>
      <v-list-item-content class="text-center mt-15">
        <span class="text-h6">Abertura/Fechamento do caixa</span>
      </v-list-item-content>
    </v-list-item>

    <v-divider />
    <div
      v-if="carregandoSituacoes"
      class="d-flex pt-5 mt-5 align-center flex-column justify-space-between"
    >
      <v-progress-circular indeterminate color="primary" :width="3" size="35" class="ml-2" />
      <span class="text-subtitle-1 font-weight-medium primary--text mt-3">Carregando dados...</span>
    </div>
    <v-list v-if="!carregandoSituacoes" dense>
      <v-list-item v-for="situacao of situacoes" :key="situacao.id" link>
        <v-list-item-avatar v-if="situacao && situacao.user">
          <v-avatar :color="colorHash.hex(situacao.user.name)">
            <span class="white--text headline">{{ situacao.user.name.charAt(0) }}</span>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-subtitle>
            <div class="d-flex flex-column">
              <!-- <div class="d-flex align-end justify-space-between"> -->
              <span class="text-subtitle-1">{{ caixa.descricao }}</span>
              <span class="text-caption">{{
                situacao.created_at | dateParse("YYYY-MM-DD hh-mm") | dateFormat("DD/MM/YYYY hh:mm")
              }}</span>
              <!-- </div> -->
              <div class="d-flex justify-space-between mt-1" v-if="situacao && situacao.user">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip v-bind="attrs" label x-small color="warning" v-on="on">
                      {{ situacao.user.name | truncate(10) }}
                    </v-chip>
                  </template>
                  <span>{{ situacao.user.name }}</span>
                </v-tooltip>
                <v-chip v-if="situacao.situacao == 1" label x-small color="success">
                  ABERTO
                </v-chip>
                <v-chip v-if="situacao.situacao == 0" label x-small color="error"> FECHADO </v-chip>
              </div>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import ColorHash from "color-hash";
import caixasService from "@/Services/CaixasService";

export default {
  name: "EHistoricoCaixa",
  model: {
    prop: "open",
    event: "input",
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    caixa: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      colorHash: new ColorHash(),
      situacoes: null,
      carregandoSituacoes: false,
    };
  },
  watch: {
    caixa(oldCaixa, newCaixa) {
      if (oldCaixa !== newCaixa) {
        this.listarSituacoes();
      }
    },
  },
  methods: {
    async listarSituacoes() {
      this.carregandoSituacoes = true;
      try {
        this.situacoes = [];
        this.situacoes = await caixasService.listarSituacoes(this.caixa);
        this.open = true;
      } catch (error) {
        this.$handleError(error);
      }
      this.carregandoSituacoes = false;
    },
  },
};
</script>
